import React from 'react';
import '../index.css'
import Tractor from '../assets/566.jpg'
import Logo from '../assets/easychef-logo.png'
import Tomatoes from '../assets/Tomatoes-scaled.jpeg'
import Chilli from '../assets/chilli-pepper.jpg'
import Pic from '../assets/2148396774.jpg'
import Pic2 from '../assets/2148580014.jpg'
import Pic3 from '../assets/andrej-lisakov-zYUn4R37o_U-unsplash.jpg'
import Soybean from '../assets/soybean-seeds-sprouting_800x.webp'
import Pexels from '../assets/pexels-alejandro-barron-21404-96715.jpg'
import { useState } from 'react';
import Footer from '../components/footer'


function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div class="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
        <div class="relative flex items-center justify-between">
          <a
            href="/"
            aria-label="Company"
            title="Company"
            class="inline-flex items-center"
          >
            <img src={Logo} className='h-10' />
            <span class="ml-3 text-lg font-urbanist tracking-wide text-dark">
              Leading Acres Farms
            </span>
          </a>
          <ul class="flex items-center hidden space-x-8 lg:flex">
            <li>
              <a
                href="/"
                class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Our Projects
              </a>
            </li>
            <li>
              <a
                href="/"
                aria-label="Our product"
                title="Our product"
                class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Resources
              </a>
            </li>
            <li>
              <a
                href="/"
                aria-label="Product pricing"
                title="Product pricing"
                class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Careers
              </a>
            </li>
            <li>
              <a
                href="/"
                aria-label="Product pricing"
                title="Product pricing"
                class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <div class="lg:hidden">
            <button
              aria-label="Open Menu"
              title="Open Menu"
              class="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
              onClick={() => setIsMenuOpen(true)}
            >
              <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                />
                <path
                  fill="currentColor"
                  d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                />
                <path
                  fill="currentColor"
                  d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                />
              </svg>
            </button>
            {isMenuOpen && (
              <div class="absolute top-0 left-0 w-full">
                <div class="p-5 bg-white border rounded shadow-sm">
                  <div class="flex items-center justify-between mb-4">

                    <div>
                      <button
                        aria-label="Close Menu"
                        title="Close Menu"
                        class="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                        onClick={() => setIsMenuOpen(false)}
                      >
                        <svg class="w-5 text-gray-600" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <nav>
                    <ul class="space-y-4">
                      <li>
                        <a
                          href="/"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Our Projects
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          aria-label="Our product"
                          title="Our product"
                          class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Resources
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          aria-label="Product pricing"
                          title="Product pricing"
                          class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Careers
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          aria-label="Product pricing"
                          title="Product pricing"
                          class="font-medium font-urbanist tracking-wide text-gray-700 transition-colors duration-200 hover:text-deep-purple-accent-400"
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <header>
        <div className="mx-auto w-full max-w-7xl px-5 py-12 md:px-10 md:py-14">
          <div className="grid items-center justify-items-start gap-8 sm:gap-20 lg:grid-cols-2">
            <div className="flex flex-col">
              <h1 className="mb-4 text-5xl font-urbanist md:text-6xl">
                Dedicated to farming for a better tomorrow
              </h1>
              <p className="mb-4 max-w-lg text-sm font-urbanist text-gray-500 sm:text-xl md:mb-5 lg:mb-8">
                Leading Acres Farm blends traditional and modern farming to produce fresh, healthy crops. "Dedicated to farming for a better tomorrow" isn't just our slogan—it's our way of life.
              </p>
              <div className="flex items-center">
                <a href="#" className="mr-5 items-center rounded-md bg-black px-6 py-3 font-manrope text-white md:mr-6 lg:mr-8"> Let's Talk </a>
              </div>
            </div>
            <img
              src={Tractor}
              alt=""
              className="inline-block h-full w-full max-w-2xl rounded-xl"
            />
          </div>
        </div>
      </header>

      <div className="px-4 py-6 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-12 lg:px-8 lg:py-6">

        <div className="grid max-w-screen-lg gap-8 lg:grid-cols-2 sm:mx-auto">
          <div className="flex flex-col justify-center">
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-urbanist font-semibold leading-5">
                  Embracing Sustainable Agriculture
                </h6>
                <p className="text-sm font-manrope text-gray-900">
                  At Leading Acres, we adopt eco-friendly practices to keep our land fertile and productive. Our sustainable methods yield healthier, more nutritious produce for the community, ensuring a greener future for all.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-urbanist font-semibold leading-5">
                  Empowering Local Communities Through Farming
                </h6>
                <p className="text-sm font-manrope text-gray-900">
                  We uplift our local community by providing employment and supporting local businesses. Our farm promotes agricultural education and fosters strong community connections, enriching lives through the power of farming.
                </p>
                <hr className="w-full my-6 border-gray-300" />
              </div>
            </div>
            <div className="flex">
              <div className="mr-4">
                <div className="flex items-center justify-center w-10 h-10 mb-3 rounded-full bg-indigo-50">
                  <svg
                    className="w-8 h-8 text-deep-purple-accent-400"
                    stroke="currentColor"
                    viewBox="0 0 52 52"
                  >
                    <polygon
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                      points="29 13 14 29 25 29 23 39 38 23 27 23"
                    />
                  </svg>
                </div>
              </div>
              <div>
                <h6 className="mb-2 font-semibold font-urbanist leading-5">
                  Innovative Farming Techniques at Leading Acres
                </h6>
                <p className="text-sm font-manrope text-gray-900">
                  At Leading Acres, we harness the power of cutting-edge practices like precision farming and hydroponics. Our forward-thinking approach enhances efficiency and productivity, paving the way for a thriving and sustainable future.
                </p>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-5">
            <img
              className="object-cover w-full h-56 col-span-2 rounded shadow-lg"
              src={Pic}
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src={Pic2}
              alt=""
            />
            <img
              className="object-cover w-full h-48 rounded shadow-lg"
              src={Pic3}
              alt=""
            />
          </div>
        </div>
      </div>

      <section class="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        <div class=" p-4"><a href="#" className="group relative block bg-black">
          <img
            alt=""
            src={Chilli}
            className="absolute inset-0 h-full w-full object-cover opacity-60 transition-opacity group-hover:opacity-25"
          />

          <div className="relative p-4 sm:p-6 lg:p-8">
            <p className="text-sm font-urbanist uppercase tracking-widest text-pink-500">Project </p>

            <p className="text-xl font-urbanist font-semibold text-white sm:text-2xl">Chilli Pepper</p>

            <div className="mt-32 sm:mt-48 lg:mt-64">
              <div
                className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
              >
                <p className="text-sm text-white font-urbanist">
                  Chili peppers, renowned for their vibrant color and spicy kick, are a staple in many culinary traditions. Our farm cultivates a variety of chili peppers, ensuring they are packed with flavor and nutrients. From mild to hot, our fresh chilies add a fiery touch to your dishes while promoting good health. We pride ourselves on sustainable farming practices

                </p>
              </div>
            </div>
          </div>
        </a>
        </div>
        <div class=" p-4"><a href="#" className="group relative block bg-black">
          <img
            alt=""
            src={Tomatoes}
            className="absolute inset-0 h-full w-full object-cover opacity-60 transition-opacity group-hover:opacity-25"
          />

          <div className="relative p-4 sm:p-6 lg:p-8">
            <p className="text-sm font-urbanist uppercase tracking-widest text-pink-500">Project </p>

            <p className="text-xl font-urbanist font-semibold text-white sm:text-2xl">Tomatoes</p>

            <div className="mt-32 sm:mt-48 lg:mt-64">
              <div
                className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
              >
                <p className="text-sm text-white font-urbanist">
                  Tomatoes, celebrated for their juicy sweetness and versatility, are a key ingredient in countless dishes. Our farm grows an assortment of tomatoes, each ripened to perfection under the sun. Rich in vitamins and antioxidants, our fresh tomatoes enhance your meals with their robust flavor and nutritional benefits.
                </p>
              </div>
            </div>
          </div>
        </a>
        </div>
        <div class="p-4"><a href="#" className="group relative block bg-black">
          <img
            alt=""
            src={Soybean}
            className="absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50"
          />

          <div className="relative p-4 sm:p-6 lg:p-8">
            <p className="text-sm font-urbanist uppercase tracking-widest text-pink-500">Project</p>

            <p className="text-xl font-urbanist font-semibold text-white sm:text-2xl">Soybean</p>

            <div className="mt-32 sm:mt-48 lg:mt-64">
              <div
                className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100"
              >
                <p className="text-sm text-white font-urbanist">
                  Soybeans, prized for their rich protein content and numerous health benefits, are a versatile crop used in many recipes. Our farm produces high-quality soybeans, cultivated with care to ensure optimal taste and nutrition. Perfect for a variety of dishes, our fresh soybeans support a healthy diet while adding a wholesome, earthy flavor.
                </p>
              </div>
            </div>
          </div>
        </a>
        </div>
      </section>


      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="grid gap-10 lg:grid-cols-2">
          <div className="lg:pr-10">
            <a
              href="/"
              aria-label="Go Home"
              title="Logo"
              className="inline-block mb-5"
            >
              <div className="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
                <svg
                  className="w-10 h-10 text-deep-purple-accent-400"
                  stroke="currentColor"
                  viewBox="0 0 52 52"
                >
                  <polygon
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    fill="none"
                    points="29 13 14 29 25 29 23 39 38 23 27 23"
                  />
                </svg>
              </div>
            </a>
            <h5 className="mb-4 text-4xl font-outfit leading-none">
              The health of soil, plant,
              <br className="hidden md:block" />
              animal, and man is one
              <span className="inline-block text-deep-purple-accent-400">
                and indivisible.
              </span>
            </h5>
            <p className="mb-6 text-gray-900 font-outfit ">
              Sir Albert Howard
            </p>
            <hr className="mb-5 border-gray-300" />
            <div className="flex items-center space-x-4">
              <a
                href="/"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                </svg>
              </a>
              <a
                href="/"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                  <circle cx="15" cy="15" r="4" />
                  <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                </svg>
              </a>
              <a
                href="/"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                  <path d="M22,0H2C0.895,0,0,0.895,0,2v20c0,1.105,0.895,2,2,2h11v-9h-3v-4h3V8.413c0-3.1,1.893-4.788,4.659-4.788 c1.325,0,2.463,0.099,2.795,0.143v3.24l-1.918,0.001c-1.504,0-1.795,0.715-1.795,1.763V11h4.44l-1,4h-3.44v9H22c1.105,0,2-0.895,2-2 V2C24,0.895,23.105,0,22,0z" />
                </svg>
              </a>
              <a
                href="/"
                className="text-gray-600 transition-colors duration-300 hover:text-deep-purple-accent-400"
              >
                <svg viewBox="0 0 24 24" fill="currentColor" className="h-6">
                  <path d="M23.8,7.2c0,0-0.2-1.7-1-2.4c-0.9-1-1.9-1-2.4-1C17,3.6,12,3.6,12,3.6h0c0,0-5,0-8.4,0.2 c-0.5,0.1-1.5,0.1-2.4,1c-0.7,0.7-1,2.4-1,2.4S0,9.1,0,11.1v1.8c0,1.9,0.2,3.9,0.2,3.9s0.2,1.7,1,2.4c0.9,1,2.1,0.9,2.6,1 c1.9,0.2,8.2,0.2,8.2,0.2s5,0,8.4-0.3c0.5-0.1,1.5-0.1,2.4-1c0.7-0.7,1-2.4,1-2.4s0.2-1.9,0.2-3.9v-1.8C24,9.1,23.8,7.2,23.8,7.2z M9.5,15.1l0-6.7l6.5,3.4L9.5,15.1z" />
                </svg>
              </a>
            </div>
          </div>
          <div>
            <img
              className="object-cover w-full h-56 rounded shadow-lg sm:h-96"
              src={Pexels}
              alt=""
            />
          </div>
        </div>
      </div>


      <section class="grid grid-cols-1 md:grid-cols-3 gap-4 p-4">
        <div class="p-4"><a href="#" className="group relative block h-64 sm:h-80 lg:h-96">
          <span className="absolute inset-0 border-2 border-dashed border-black"></span>

          <div
            className="relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2"
          >
            <div
              className="p-4 !pt-0 transition-opacity group-hover:absolute group-hover:opacity-0 sm:p-6 lg:p-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 sm:size-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <h2 className="mt-4 text-xl font-outfit sm:text-2xl">Global Food Security Trends</h2>
            </div>

            <div
              className="absolute p-4 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100 sm:p-6 lg:p-8"
            >
              <h3 className="mt-4 text-xl font-outfit sm:text-2xl">Global Food Security Trends</h3>

              <p className="mt-4 text-sm font-urbanist sm:text-base">
                The ability to access sufficient, safe, and nutritious food is fundamental to human existence, yet numerous challenges threaten this basic necessity
              </p>

              <p className="mt-8 font-bold">Read more</p>
            </div>
          </div>
        </a>
        </div>
        <div class="p-4"><a href="#" className="group relative block h-64 sm:h-80 lg:h-96">
          <span className="absolute inset-0 border-2 border-dashed border-black"></span>

          <div
            className="relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2"
          >
            <div
              className="p-4 !pt-0 transition-opacity group-hover:absolute group-hover:opacity-0 sm:p-6 lg:p-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 sm:size-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <h2 className="mt-4 text-xl font-outfit sm:text-2xl">Soybean: Farming, Uses, and Benefits</h2>
            </div>

            <div
              className="absolute p-4 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100 sm:p-6 lg:p-8"
            >
              <h3 className="mt-4 text-xl font-outfit sm:text-2xl">Soybean: Farming, Uses, and Benefits</h3>

              <p className="mt-4 text-sm font-urbanist sm:text-base">
              Soybean often referred to as the "miracle bean," is a versatile legume that has become a staple in agriculture due to its numerous uses and benefits. 
              </p>

              <p className="mt-8 font-bold">Read more</p>
            </div>
          </div>
        </a>
        </div>
        <div class=" p-4"><a href="#" className="group relative block h-64 sm:h-80 lg:h-96">
          <span className="absolute inset-0 border-2 border-dashed border-black"></span>

          <div
            className="relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2"
          >
            <div
              className="p-4 !pt-0 transition-opacity group-hover:absolute group-hover:opacity-0 sm:p-6 lg:p-8"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-10 sm:size-12"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>

              <h2 className="mt-4 text-xl font-outfit sm:text-2xl">Principles of Sustainable Agriculture</h2>
            </div>

            <div
              className="absolute p-4 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100 sm:p-6 lg:p-8"
            >
              <h3 className="mt-4 text-xl font-outfit sm:text-2xl">Principles of Sustainable Agriculture</h3>

              <p className="mt-4 text-sm font-urbanist sm:text-base">
              Sustainable agriculture is an essential concept in modern farming, aimed at balancing the need for food production with the preservation of environmental health. 
              </p>

              <p className="mt-8 font-bold">Read more</p>
            </div>
          </div>
        </a>
        </div>
      </section>



      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12 font-urbanist">
              <p className="max-w-xl text-lg">
              At Leading Acres Farms, we value your feedback and inquiries. Whether you have questions or want to learn more about our fresh, healthy crops, we're here to help.
              </p>

              <div className="mt-8">
                <a href="#" className="text-2xl font-bold text-green-700"> +233 (0) 59 288 8721 </a>
                <address className="mt-2 not-italic">support@leadingacres.com</address>

                <address className="mt-2 not-italic">Agyanoa - Akuapim South</address>
                <address className=" not-italic">Ghana</address>

              </div>
            </div>

            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form action="#" className="space-y-4">
                <div>
                  <label className="sr-only" htmlFor="name">Name</label>
                  <input
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Name"
                    type="text"
                    id="name"
                  />
                </div>

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label className="sr-only" htmlFor="email">Email</label>
                    <input
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Email address"
                      type="email"
                      id="email"
                    />
                  </div>

                  <div>
                    <label className="sr-only" htmlFor="phone">Phone</label>
                    <input
                      className="w-full rounded-lg border-gray-200 p-3 text-sm"
                      placeholder="Phone Number"
                      type="tel"
                      id="phone"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  <div>
                    <label
                      htmlFor="Option1"
                      className="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                      tabIndex="0"
                    >
                      <input className="sr-only" id="Option1" type="radio" tabIndex="-1" name="option" />

                      <span className="text-sm"> Option 1 </span>
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="Option2"
                      className="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                      tabIndex="0"
                    >
                      <input className="sr-only" id="Option2" type="radio" tabIndex="-1" name="option" />

                      <span className="text-sm"> Option 2 </span>
                    </label>
                  </div>

                  <div>
                    <label
                      htmlFor="Option3"
                      className="block w-full cursor-pointer rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black has-[:checked]:border-black has-[:checked]:bg-black has-[:checked]:text-white"
                      tabIndex="0"
                    >
                      <input className="sr-only" id="Option3" type="radio" tabIndex="-1" name="option" />

                      <span className="text-sm"> Option 3 </span>
                    </label>
                  </div>
                </div>

                <div>
                  <label className="sr-only" htmlFor="message">Message</label>

                  <textarea
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Message"
                    rows="8"
                    id="message"
                  ></textarea>
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Send Enquiry
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <Footer />



    </>
  );
}

export default App;

import React from 'react';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Homepage from './pages/home'
import Projects from './pages/ourprojects'
import Resources from './pages/resources'

const App = () => {
  return (


      <div>
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<Homepage />} />  
                  <Route path="/ourprojects" element={<Projects />} />  
                  <Route path="/resources" element={<Resources />} />  
              </Routes>
          </BrowserRouter>
          {
              
          }

          </div>
             )
}

export default App;
